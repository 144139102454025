import React from "react";
const Team = () => {
  return (
    <section id="team" className="team section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Leadership Team</h2>
        <p>
          ...............
        </p>
      </div>
      <div className="container">
        <div className="row gy-5 d-flex justify-content-center">
          <div
            className="col-lg-4 col-md-6 member"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="member-img">
              <img src="" className="img-fluid" alt="" />
              {/* <div className="social">
                <a href="#">
                  <i className="bi bi-twitter-x"></i>
                </a>
                <a href="#">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="#">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="#">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div> */}
            </div>
            <div className="member-info text-center">
              <h4></h4>
              <span></span>
              <p>
                ...............
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
