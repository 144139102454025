import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
// import client1 from "../../assets/img/clients/client-1.png";
// import client2 from "../../assets/img/clients/client-2.png";
// import client3 from "../../assets/img/clients/client-3.png";
// import client4 from "../../assets/img/clients/client-4.png";
// import client5 from "../../assets/img/clients/client-5.png";
// import client6 from "../../assets/img/clients/client-6.png";
// import client7 from "../../assets/img/clients/client-7.png";
// import client8 from "../../assets/img/clients/client-8.png";
import ImgNotFound from "../../assets/img/clients/ImgNotFound.png";
const Clients = () => {
  return (
    <section id="clients" className="clients section">
      <div className="container section-title" data-aos="fade-up">
        <h2>What Our Customers Say</h2>
        <p>
          We strive to provide the best value to our customers. Read some of the
          genuine reviews and feedback they've shared with us
        </p>
      </div>
      <div className="container" data-aos="fade-up">
        <div className="swiper clients-swiper">
          <div className="swiper-wrapper align-items-center">
            <Swiper
              speed={600}
              breakpoints={{
                480: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 60,
                },
              }}
              pagination={true}
              // navigation={true}
              centeredSlides={true}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              // navigation={{
              //   el: ".swiper-pagination",
              //   type: "bullets",
              //   clickable: true,
              // }}
              modules={[Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="testimonial-item">
                  <div className="d-flex justify-content-center">
                    <div>
                      <h5>A1 Masala Mart</h5>
                      <div className="stars d-flex justify-content-center">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left"></i>
                    <span>
                      Eyedyll platform has revolutionised how we connect with
                      our customers. Going from having no digital presence to
                      becoming one of the best has been an incredible journey.
                      We now feel more connected than ever.
                    </span>
                    <i className="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-item">
                <div className="d-flex justify-content-center">
                    <div>
                      <h5>Anonymous</h5>
                      <div className="stars d-flex justify-content-center">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left"></i>
                    <span>
                      Eyedyll analytics give me clear, real-time insights, and I
                      can even run the store from my phone if I need to. If
                      you’re a merchant looking to level up, I highly recommend
                      giving Eyedyll a try—it’s made a huge difference for us!
                    </span>
                    <i className="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="swiper-pagination">
        <button className="btn swiper-button-next">
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "2.8rem" }}
          >
            arrow_forward
          </span>
        </button>
        <button className="btn swiper-button-prev">
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "2.8rem" }}
          >
            arrow_back
          </span>
        </button>
      </div>
    </section>
  );
};

export default Clients;
