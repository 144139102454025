import React from "react";
import heroSecBg from "../../assets/img/hero-bg.png";
// import heroSecVid from "../../assets/hero-warp.mp4";
const Hero = () => {
  return (
    <section id="hero" className="hero section">
      {/* <img src={heroSecBg} alt="" data-aos="fade-in" /> */}

      <div className="container">
        <div className="row justify-content-center" data-aos="zoom-out">
          <div className="col-xl-7 col-lg-9 text-center">
            <h1>AI-driven tech platform for small businesses</h1>
            <p className="moto">
              <span>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.75 22.25H10.25C9.425 22.25 8.75 21.575 8.75 20.75V17.75H10.25V20.75H20.75V10.25H17.75V8.75H20.75C21.575 8.75 22.25 9.425 22.25 10.25V20.75C22.25 21.575 21.575 22.25 20.75 22.25Z"
                    fill="#29A08C"
                  />
                  <path
                    d="M15.5 13.25C15.125 13.25 14.825 13.325 14.525 13.475L11.525 10.475C11.675 10.175 11.75 9.875 11.75 9.5C11.75 8.225 10.775 7.25 9.5 7.25C8.225 7.25 7.25 8.225 7.25 9.5C7.25 10.775 8.225 11.75 9.5 11.75C9.875 11.75 10.175 11.675 10.475 11.525L13.475 14.525C13.325 14.825 13.25 15.125 13.25 15.5C13.25 16.775 14.225 17.75 15.5 17.75C16.775 17.75 17.75 16.775 17.75 15.5C17.75 14.225 16.775 13.25 15.5 13.25ZM8.75 9.5C8.75 9.05 9.05 8.75 9.5 8.75C9.95 8.75 10.25 9.05 10.25 9.5C10.25 9.95 9.95 10.25 9.5 10.25C9.05 10.25 8.75 9.95 8.75 9.5ZM15.5 16.25C15.05 16.25 14.75 15.95 14.75 15.5C14.75 15.05 15.05 14.75 15.5 14.75C15.95 14.75 16.25 15.05 16.25 15.5C16.25 15.95 15.95 16.25 15.5 16.25Z"
                    fill="#29A08C"
                  />
                  <path
                    d="M4.25 2.75H14.75C15.575 2.75 16.25 3.425 16.25 4.25V7.25H14.75V4.25H4.25V14.75H7.25V16.25H4.25C3.425 16.25 2.75 15.575 2.75 14.75V4.25C2.75 3.425 3.425 2.75 4.25 2.75Z"
                    fill="#29A08C"
                  />
                </svg>
              </span>
              <span className="motive"> Connect </span>
              <span>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 7.75V9.5H19C19.663 9.5 20.2989 9.76339 20.7678 10.2322C21.2366 10.7011 21.5 11.337 21.5 12V20C21.5 20.663 21.2366 21.2989 20.7678 21.7678C20.2989 22.2366 19.663 22.5 19 22.5H6C5.33696 22.5 4.70107 22.2366 4.23223 21.7678C3.76339 21.2989 3.5 20.663 3.5 20V12C3.5 11.337 3.76339 10.7011 4.23223 10.2322C4.70107 9.76339 5.33696 9.5 6 9.5H6.5V7.75C6.5 4.345 9.003 1.5 12.5 1.5C15.292 1.5 17.471 3.325 18.218 5.81C18.2491 5.90504 18.2608 6.00534 18.2526 6.105C18.2443 6.20466 18.2163 6.30166 18.17 6.39029C18.1237 6.47893 18.0601 6.55742 17.9831 6.62114C17.906 6.68486 17.817 6.73253 17.7212 6.76133C17.6255 6.79014 17.5249 6.79951 17.4255 6.78889C17.326 6.77826 17.2297 6.74786 17.1422 6.69947C17.0547 6.65109 16.9778 6.58569 16.9159 6.50712C16.854 6.42856 16.8085 6.33842 16.782 6.242C16.21 4.34 14.579 3 12.5 3C9.922 3 8 5.08 8 7.75ZM5 12V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H19C19.2652 21 19.5196 20.8946 19.7071 20.7071C19.8946 20.5196 20 20.2652 20 20V12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11H6C5.73478 11 5.48043 11.1054 5.29289 11.2929C5.10536 11.4804 5 11.7348 5 12Z"
                    fill="#29A08C"
                  />
                </svg>
              </span>{" "}
              <span className="motive">Unlock</span>{" "}
              <span className="motive">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1_624)">
                    <path
                      d="M9.5938 4.45219V6.86404H7.32036H5.04692V7.36551V7.86699H7.83599H10.625L10.6344 6.19541L10.6485 4.52382L13.5688 7.49446L16.4844 10.4699L13.5547 13.4548L10.625 16.4398V14.73V13.025L6.32661 13.0346L2.02349 13.0489L2.00942 13.5599L1.99536 14.0757H5.79692H9.5938V16.4876C9.5938 17.8153 9.60786 18.8994 9.6313 18.8994C9.68286 18.8994 17.8907 10.5224 17.8907 10.4699C17.8907 10.4173 9.68286 2.04033 9.62661 2.04033C9.60786 2.04033 9.5938 3.12447 9.5938 4.45219Z"
                      fill="#29A08C"
                    />
                    <path
                      d="M0.5 10.4702V10.9717H3.28906H6.07812V10.4702V9.96873H3.28906H0.5V10.4702Z"
                      fill="#29A08C"
                    />
                    <path
                      d="M18.7344 12.9439V14.6012H16.9531H15.1719V15.1026V15.6041H17.4453H19.7188V14.6871V13.7654L21.3922 15.4704L23.0703 17.1802L21.4297 18.8518C20.5297 19.7687 19.775 20.5233 19.7563 20.5233C19.7328 20.5233 19.7188 20.1126 19.7188 19.6159V18.7085H15.4297H11.1406V19.2338V19.7592H14.9375H18.7344L18.7438 21.383L18.7578 23.0068L21.6313 20.084L24.5 17.1563L21.6172 14.2191L18.7344 11.2819V12.9439Z"
                      fill="#29A08C"
                    />
                    <path
                      d="M2.01877 16.1916C2.00471 16.2298 2.00471 16.459 2.0094 16.7074L2.02346 17.1563L4.80315 17.1706L7.57815 17.1802V16.6548V16.1295H4.81252C2.55783 16.1295 2.03752 16.139 2.01877 16.1916Z"
                      fill="#29A08C"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_624">
                      <rect
                        width="24"
                        height="24.4528"
                        fill="white"
                        transform="translate(0.5 0.273438)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>{" "}
              <span className="motive">Accelerate</span>{" "}
              <span className="growth">Growth</span>
            </p>
          </div>
        </div>
        <div className="text-center" data-aos="zoom-out" data-aos-delay="100">
          <a href="/" className="btn-get-started">
            Download Now
          </a>
        </div>
        <div className="row gy-4 mt-5">
          <div className=" text-center">
            <img
              src={heroSecBg}
              alt=""
              className="img-fluid"
              data-aos="fade-in"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
