import React, { useEffect } from "react";
import logo from "../../assets/img/eyedyllLogo.png";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import {
  toggleScrolled,
  mobileNavToogle,
  hideMobileNavOnHashLinks,
  toggleMobileNavDropdowns,
  removePreloader,
  toggleScrollTop,
  scrollTopOnClick,
  aosInit,
  initGLightbox,
  initIsotopeLayout,
  correctScrollingPosition,
  navmenuScrollspy,
} from "../Common/main";
const Header = () => {
  useEffect(() => {
    // Add event listeners and initiate functionalities
    // document.addEventListener("scroll", toggleScrolled);
    // window.addEventListener("load", toggleScrolled);

    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");
    mobileNavToggleBtn?.addEventListener("click", mobileNavToogle);

    hideMobileNavOnHashLinks();
    toggleMobileNavDropdowns();
    removePreloader();

    window.addEventListener("scroll", toggleScrollTop);
    scrollTopOnClick();

    // window.addEventListener("load", aosInit);
    aosInit();
    initGLightbox();
    initIsotopeLayout();

    correctScrollingPosition();
    navmenuScrollspy();

    // Cleanup event listeners on unmount
    return () => {
      // document.removeEventListener("scroll", toggleScrolled);
      // window.removeEventListener("load", toggleScrolled);
      mobileNavToggleBtn?.removeEventListener("click", mobileNavToogle);
      window.removeEventListener("scroll", toggleScrollTop);
      // window.removeEventListener("load", aosInit);
      // window.removeEventListener("load", initGLightbox);
      // window.removeEventListener("load", initIsotopeLayout);
    };
  }, []);
  return (
    <header id="header" className="header d-flex align-items-center sticky-top">
      <div className="container position-relative d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center">
          <img src={logo} alt="" />
          <h1 className="sitename" hidden>
            Eyedyll
          </h1>
        </a>

        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <a href="/#hero" className="active">
                Home
              </a>
            </li>
            {/* <li>
              <a href="/">Vision</a>
            </li> */}
            {/* <li>
              <a href="/">Solution</a>
            </li> */}
            {/* <li>
              <Link to="/about-us">About</Link>
            </li> */}
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            {/* <li>
              <a href="index.html#contact">Contact</a>
            </li> */}
            {/* <li>
              <button className="btn btn-getstarted in-list">
                Get Started
              </button>
            </li> */}
          </ul>
          <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>
      </div>
    </header>
  );
};

export default Header;
