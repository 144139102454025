import React from "react";
// import { Waypoint } from "react-waypoint";

import Contact from "./Contact";
// import FAQ from "./FAQ";
import Blogs from "./Blogs";
import Clients from "./Clients";
import Hero from "./Hero";
import Features from "./Features";
// import Testimonials from "./Testimonials";
// const SkillsAnimationItem = ({ children }) => {
//   const handleWaypointEnter = () => {
//     const progressBars = document.querySelectorAll(".progress .progress-bar");
//     progressBars.forEach((bar) => {
//       const val = bar.getAttribute("aria-valuenow");
//       bar.style.width = `${val}%`;
//     });
//   };

//   return (
//     <Waypoint onEnter={handleWaypointEnter}>
//       <div className="row skills-content skills-animation">{children}</div>
//     </Waypoint>
//   );
// };
const Body = () => {
  return (
    <main className="main">
      <Hero />
      <Features />
      <Blogs />
      <Clients />
      {/* <FAQ /> */}
      {/* <Testimonials /> */}
      {/* <Contact /> */}
    </main>
  );
};

export default Body;
